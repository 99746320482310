import "./App.css";
// import { Online } from "react-detect-offline";
// import OfflinePage from "./components/common/offline/offline";
import HomePage from "./pages/HomePage";
function App() {
  return (
    <div className="App">
      
        <HomePage />
     
     
    </div>
  );
}

export default App;
