import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

export default function PendingDonationCard(props) {
  const deleteDonation = (id) => {
    swal({
      title: "Are you sure?",
      text: "Item Is Not Available Once Deleted...",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete(`http://localhost:8070/donator/deleteDonation/${id}`)
          .then((res) => {
            if (willDelete) {
              swal("Item Succesfully Deleted!!", {
                icon: "success",
              });
              setTimeout(function () {
                window.location.reload();
              }, 1000);
              console.log(res);
            }
            // } else {
            //               swal("File Is Not Deleted");
            //             }
          })
          .catch((e) => {
            swal("File Is Not Deleted");
          });
      }
    });
  };
  return (
    <div>
      <div
        class="courses-container"
        // style={{
        //   width: 500,
        // }}
      >
        <div class="course">
          <div class="course-info">
            <div class="progress-container">
              {/* <div class="progress"></div> */}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="red"
                class="bi bi-trash-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
              </svg>

              <span onClick={() => deleteDonation(props._id)}> Delete</span>

              <br />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              <Link to={`/donator/dashboard/donator/editDonation/${props._id}`}>
                <span data-toggle="modal" data-target="#exampleModalCenter">
                  {" "}
                  Edit
                </span>
              </Link>
              {/* <span class="progress-text">6/9 Challenges</span> */}
            </div>
            <Link
              to={"/donator/view/" + props._id}
              style={{
                color: "black",
              }}
            >
              {" "}
              <h2>{props.donationTitle}</h2>{" "}
            </Link>
            <h6>{props.donationDescription}</h6>
            <br></br>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}
